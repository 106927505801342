<template>
  <div v-if="isMounted">
    <div class="vx-row no-gutter items-center justify-center">
      <div v-if="showSupplierWelcomeMessage" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
          <vx-card class="login-card py-3 px-3">
            <div slot="no-body" class="">
              <div class="vx-col sm:w-full">
                <div class="login-tabs-container">
                  <div class="vx-row m-0">
                    <div class="vx-col w-full p-0" style="min-height: 200px">
                      <!-- MAIN-DIV -->
                      <div class="px-6 pt-5 pb-5">
                        <!-- HEADER -->
                        <div class="vx-row mb-4">
                          <div class="vx-col w-full">
                            <p class="text-2xl card-sub-title">¡Te damos la bienvenida! </p>
                          </div>
                        </div>
                        <vs-divider color="dark"></vs-divider>
                        <!-- CONTENT -->
                        <div class="main-form mt-base">
                          <div class="vx-row">
                            <div :class="colClassFull">
                              <p>
                                En la Red nos interesa siempre mantener el expediente de nuestros integradores actualizado, 
                                es por esto que necesitamos que nos ayudes a
                                actualizarlo.
                                <br>
                                <br>
                                <strong>¡Gracias!</strong>
                              </p>
                            </div>
                          </div>
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                            <div class="vx-col md:w-1/2 sm:w-full w-full">
                              <vs-button class="w-full mt-4" @click="showSupplierWelcomeMessage = false">Actualizar
                                datos</vs-button>
                            </div>
                          </div>
                        </div>
                        <!-- END CONTENT DIV -->
                      </div>
                      <!-- END MAIN-DIV -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </div>
    <div v-if="!showSupplierWelcomeMessage">
      <div class="vx-row no-gutter items-center justify-center">
        <supplier-onboarding-progress-bar
          :class="(currentOnboardingStep == 'pm_mex_1_signup' || currentOnboardingStep == 'pf_mex_1_signup') ? 'test': ''"
          :onboarding-step-data="getSupplierPhoneData" @updated="getSupplierData" :currentStep="currentOnboardingStep" />
        <supplier-onboarding-phone
          v-if="(currentOnboardingStep == 'pm_mex_1_signup' || currentOnboardingStep == 'pf_mex_1_signup')"
          :onboarding-step-data="getSupplierPhoneData" @updated="getSupplierData" />
        <supplier-onboarding-general
          v-if="(currentOnboardingStep == 'pm_mex_2_cellphone_checked' || currentOnboardingStep == 'pf_mex_2_cellphone_checked')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <supplier-onboarding-fiscal-profile
          v-if="(currentOnboardingStep == 'pm_mex_3_name' || currentOnboardingStep == 'pf_mex_3_name')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <supplier-onboarding-address
          v-if="(currentOnboardingStep == 'pm_mex_4_fiscal_profile' || currentOnboardingStep == 'pf_mex_4_fiscal_profile')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <!-- TODO: SOLO PARA PM -->
        <!-- <supplier-onboarding-credit-score v-if="currentOnboardingStep == 'pm_mex_6_address'" :onboarding-step-data="base"
          :is-moral="isMoral" @updated="getSupplierData" :isBusiness="false" /> -->
        <supplier-onboarding-first-check-point
          v-if="(currentOnboardingStep == 'pm_mex_7_business_credit_history_report' || currentOnboardingStep == 'pm_mex_6_address' || currentOnboardingStep == 'pf_mex_5_address')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <template v-if="(currentOnboardingStep == 'pm_mex_8_business_checkpoint_1' || currentOnboardingStep == 'pf_mex_6_business_checkpoint_1')">
          <!-- EN ESTE PASO PEDIMOS 2 COSAS, PRIMERO LA INFORMACIÓN DEL REPRESENTANTE LEGAL Y DESPUÉS PEDIMOS LA VERIFICACIÓN DE IDENTIDAD -->
          <supplier-onboarding-rep-leg-identity-verification 
            v-if="showRepLegalIdentityVerification"
            :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData"
            :identity-status="identityStatus" />
          <supplier-onboarding-legal-representative-data 
            v-else
            :repLegalData="isRepLegalCompleted"
            :onboarding-step-data="base" :is-moral="isMoral" @completed="repLegalCompleted" @updated="getSupplierData" />
        </template>
        <!-- TODO: REVISIÓN DEL CDC PARA LOS REP LEG sólo PM -->
        <!-- <supplier-onboarding-credit-score
          v-if="(currentOnboardingStep == 'pm_mex_9_id_verification_legal_representative' || currentOnboardingStep == 'pf_mex_7_id_verification')" :onboarding-step-data="base"
          :is-moral="isMoral" @updated="getSupplierData" :isBusiness="true"/> -->
        <!-- <supplier-onboarding-social-network
          v-if="(currentOnboardingStep == 'pm_mex_9_id_verification_legal_representative' || currentOnboardingStep == 'pf_mex_7_id_verification' || currentOnboardingStep == 'pm_mex_10_legal_representative_credit_history_report' || currentOnboardingStep == 'pf_mex_8_credit_history_report')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <supplier-onboarding-coverage
          v-if="(currentOnboardingStep == 'pm_mex_11_social_networks' || currentOnboardingStep == 'pf_mex_9_social_networks')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <supplier-onboarding-contacts
          v-if="(currentOnboardingStep == 'pm_mex_12_coverage' || currentOnboardingStep == 'pf_mex_10_coverage')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        <supplier-onboarding-second-check-point
          v-if="(currentOnboardingStep == 'pm_mex_13_main_contacts' || currentOnboardingStep == 'pf_mex_11_main_contacts')"
          :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" /> -->
        <supplier-onboarding-finish-message 
          v-if="ShowSectionToCompleteSupplierOnboarding"
          :onboarding-step-data="base" :is-moral="isMoral"
        />
      </div>
    </div>
  </div>
</template>
  
<script>
import inputHelper from "@mixins/inputHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import SupplierOnboardingPhone from "./components/SupplierOnboardingPhone.vue";
import SupplierOnboardingGeneral from "./components/SupplierOnboardingGeneral.vue";
import SupplierOnboardingFiscalProfile from "./components/SupplierOnboardingFiscalProfile.vue";
import SupplierOnboardingAddress from "./components/SupplierOnboardingAddress.vue";
import SupplierOnboardingCreditScore from "./components/SupplierOnboardingCreditScore.vue";
import SupplierOnboardingFirstCheckPoint from "./components/SupplierOnboardingFirstCheckPoint.vue";
import SupplierOnboardingLegalRepresentativeData from "./components/SupplierOnboardingLegalRepresentativeData.vue";
import SupplierOnboardingRepLegIdentityVerification from "./components/SupplierOnboardingRepLegIdentityVerification.vue";
import SupplierOnboardingSocialNetwork from "./components/SupplierOnboardingSocialNetwork.vue";
import SupplierOnboardingCoverage from "./components/SupplierOnboardingCoverage.vue";
import SupplierOnboardingContacts from "./components/SupplierOnboardingContacts.vue";
import SupplierOnboardingSecondCheckPoint from "./components/SupplierOnboardingSecondCheckPoint.vue";
import SupplierOnboardingProgressBar from "./components/SupplierOnboardingProgressBar.vue";
import SupplierOnboardingFinishMessage from "./components/SupplierOnboardingFinishMessage.vue";

const requiredObjects = [
  'user.personal.address',
  'user.personal.fiscalAddress',
  'user.personal.businessAddress',
  'user.personal.bankAccounts',
  'user.personal.tins',
  'user.personal.creditProfile',
  'user.personal.phone',
  'user.business.personal',
  'user.business.address',
  'user.business.tins',
  'user.business.phone',
  'user.business.personal.address',
  'user.business.personal.phone',
  'user.business.fiscalAddress',
  'user.business.bankAccounts.bank',
  'user.business.personal.creditProfile',
  'contacts',
  'tools',
  'customerTypes'
];

export default {
  name: "SupplierOnboardingStepPage",
  mixins: [inputHelper, userIdentityHelper],
  components: {
    SupplierOnboardingPhone,
    SupplierOnboardingGeneral,
    SupplierOnboardingFiscalProfile,
    SupplierOnboardingAddress,
    SupplierOnboardingCreditScore,
    SupplierOnboardingFirstCheckPoint,
    SupplierOnboardingRepLegIdentityVerification,
    SupplierOnboardingSocialNetwork,
    SupplierOnboardingCoverage,
    SupplierOnboardingContacts,
    SupplierOnboardingSecondCheckPoint,
    SupplierOnboardingProgressBar,
    SupplierOnboardingLegalRepresentativeData,
    SupplierOnboardingFinishMessage
  },
  async beforeMount(){
    if(this.isAgent) {
      await this.$router.replace({name: 'supplierAgentOnboarding'});
    }
  },
  async mounted() {
    this.isMounted = false;
    await this.getSupplierData();
    if(this.SupplierStatus >= 4) {
      this.showSupplierWelcomeMessage = true;
    }
    this.identityStatus = await this.getUserIdentityStatus(this.UserId);
    if (this.identityStatus == 1 || this.identityStatus == 3) {
      this.isRepLegalCompleted = true;
    }
    // console.log(this.currentOnboardingStep);
    this.isMounted = true;
  },
  data() {
    return {
      isMounted: false,
      showSupplierWelcomeMessage: false,
      colClassFull: "vx-col w-full mb-5",
      onboardingStepData: {
        onboarding_step: null,
        phone_number: null,
        rfc: null,
        curp: null,
      },
      base: {},
      isRepLegalCompleted: false,
      identityStatus: null
    }
  },
  computed: {
    currentOnboardingStep() {
      return this.base.onboarding_step;
    },
    personType() {
      return this.base.user.person_type;
    },
    isMoral() {
      return this.personType == 0 || this.personType == 3;
    },
    personal() {
      return this.base.user.personal;
    },
    business() {
      return this.base.user.business;
    },
    owner() {
      return this.base.user.business.personal;
    },
    getSupplierPhoneData() {
      return !this.isMoral ? this.personal.phone : this.owner.phone;
    },
    showRepLegalIdentityVerification() {
      return this.isRepLegalCompleted;
    },
    isAgent() {
      return this.$acl.check('isAgent');
    },
  },
  methods: {
    async getSupplierData() {
      try {
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v1/supplier/${this.SupplierId}?${params}`);
        this.base = response.data;
        this.user.supplier_onboarding_step = this.base.onboarding_step;
        window.scrollTo(0,0);
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    startOnboarding() {
      this.showSupplierWelcomeMessage = false;
    },
    async repLegalCompleted() {
      this.isRepLegalCompleted = true;
      await this.getSupplierData();
    }
  }
}
</script>