<template>
    <div id="supplier_credit_score" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">
                                                    Historial crediticio del {{ getTitleName }}
                                                </h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                                <CreditScoreRequest
                                                    :isMoral="isMoral"
                                                    :currentUser="base.user"
                                                    :isBusiness="isBusiness"
                                                    @updated="finishCreditScore"></CreditScoreRequest>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button class="mt-2 vs-button-dark"
                                                        @click="finishCreditScore()">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import CreditScoreRequest from '@components/profile_shared/CreditScoreRequest.vue';

export default {
    name: "SupplierOnboardingCreditScore",
    components: {
        CreditScoreRequest
    },
    props: ["onboardingStepData", "isMoral", "isBusiness"],
    data() {
        return {
            requiredRules: "required",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
        };
    },
    async mounted() {
        this.isMounted = false;
        this.base = this.onboardingStepData;
        this.isMounted = true;
    },
    computed: {
        personal() {
            return this.base.user.personal;
        },
        business() {
            return this.base.user.business;
        },
        getTitleName() {
            let text = "negocio";

            if(this.isMoral) {
                if(this.isBusiness) {
                    text = "Representante legal";
                }
            } else {
                text = "Representante o dueño del negocio";
            }

            return text;
        }
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        async finishCreditScore() {
            try {
                if(this.isMoral) {
                    if(this.isBusiness) {
                        await this.checkIfCanUpdateSupplierOnboardingStep("owner-score");
                    } else {
                        await this.checkIfCanUpdateSupplierOnboardingStep("pm-business-score");
                    }
                } else {
                    await this.checkIfCanUpdateSupplierOnboardingStep("owner-score");
                }
                await this.$emit("updated", 1);
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>

<style>

</style>
