<template>
    <div id="supplier_fiscal_profile" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">¡Muchas gracias!</h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form mt-base">
                                            <p class="mb-8">
                                                <strong>¡Muchas gracias!</strong> por confiar en <strong>RedGirasol</strong>.
                                                Estás a muy poco de formar parte de la Red y hacer equipo con nosotros.
                                                <br>
                                                <br>
                                                <strong>¡Ya falta poco!</strong>
                                            </p>

                                            <vs-button @click.stop="goToHome()" color="dark">Continuar registro</vs-button>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "SupplierOnboardingFinishMessage",
    props: ["onboardingStepData", "isMoral"],
    data() {
        return {

        };
    },
    async beforeMount() {
        if(this.onboardingStepData.verified_date != null){
            await this.$router.replace({name: 'SupplierOnboardingHomePage'});
        }
    },
    async mounted() {

    },
    computed: {

    },
    watch: {

    },
    methods: {
        async goToHome() {
            await this.$router.replace({name: 'SupplierOnboardingHomePage'});
        }
    },
};
</script>

<style>

</style>
