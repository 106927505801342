<template>
    <div id="supplier-onboarding-progress-bar" class="vx-col sm:w-full md:w-2/3 lg:w-2/3 xxl:2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center pl-6 pr-6 pt-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-1">
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form">
                                            <vs-progress :percent="percentageStep" :height="10" color="primary"></vs-progress>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
    props: ["onboardingStepData", "isMoral", "currentStep"],
    data() {
        return {
            requiredRules: "required",
            errorMssg: null,
            successMssg: null,
            isMounted: true,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
        };
    },
    computed: {
        currentStepNumber() {
            const step = this.currentStep[7] + this.currentStep[8];
            return parseInt(step);
        },
        percentageStep() {
            const totalSteps = this.isMoral ? 20 : 18;
            return (this.currentStepNumber * 100) / totalSteps;
        }
    }
};
</script>

<style>

@media (min-width: 1200px) and (max-width: 1360px) {
    .test {
        margin-left: 21.5rem !important;
        margin-right: 21.33rem !important;
    }
}
@media (max-width: 500px) {
    .test {
        width: 100%;
    }
}
@media (min-width: 1370px) {
    .test {
        margin-left: 36rem !important;
        margin-right: 36rem !important;
    }
}
</style>
