<template>
    <div id="supplier_fiscal_profile" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">Perfil fiscal</h1>
                                                <p>
                                                    Revisa la Constancia de Situación Fiscal, por favor introduce la información tal como aparece ahí.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                            <UserTaxProfile ref="taxProfile" :showButton="false"/>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button class="mt-2 vs-button-dark"
                                                        @click="saveTaxProfile()">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import UserTaxProfile from '@components/profile_shared/UserTaxProfile';

export default {
    name: "SupplierOnboardingFiscalProfile",
    components: {
        UserTaxProfile
    },
    props: ["onboardingStepData", "isMoral"],
    data() {
        return {
            requiredRules: "required",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
        };
    },
    async mounted() {
        this.isMounted = false;
        this.base = this.onboardingStepData;
        this.isMounted = true;
    },
    computed: {
        personal() {
            return this.base.user.personal;
        },
        business() {
            return this.base.user.business;
        },
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        async saveTaxProfile() {
            try {
                this.showLoading(true, "Guardando información...");
                const res = await this.$refs.taxProfile.saveDataForm();
                if(res == true) {
                    await this.checkIfCanUpdateSupplierOnboardingStep("tax-profile");
                    await this.$emit("updated", 1);
                }
                this.showLoading(false);
                
            } catch (error) {
                this.showLoading(false);
                console.log(error);
            }
        }
    },
};
</script>

<style>

</style>
