<template>
    <div id="rep-leg-id-verification" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">Verificación de identidad del representante legal</h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                            <div class="vx-row">
                                                <supplier-identity-verification
                                                    ref="supplier_identity_verification"
                                                    :isMoral="isMoral"
                                                    :userId="UserId"
                                                    :personType="base.user.person_type"
                                                    :supplierId="SupplierId"
                                                    :showButton="false"
                                                />
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button class="mt-2 vs-button-dark"
                                                        @click="shouldRunMatiProcess(identityStatus) ? executeMatiPopUp() : nextStep()">
                                                            {{ shouldRunMatiProcess(identityStatus) ? "Comenzar" : "Continuar" }}
                                                        </vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
    import userIdentityHelper from "@/helpers/userIdentityHelper";
    import SupplierIdentityVerification from '@supplier/SupplierIdentityVerification.vue';
    export default {
        name: "SupplierOnboardingRepLegidentityVerification",
        props: ["onboardingStepData", "isMoral", "identityStatus"],
        mixins: [userIdentityHelper],
        components: {
            SupplierIdentityVerification,
        },
        data: () => ({
            requiredRules: "required",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
        }),
        mounted() {
            this.isMounted = true;
            this.$store.subscribe(mutation => {
                if(mutation.type == 'SET_MATI_PROCESS_IS_FINISHED'){
                    this.executeNextOnboardingStep();
                }
            });
        },
        computed: {
            base() {
                return this.onboardingStepData;
            }
        },
        methods: {
            executeMatiPopUp() {
                this.$refs.supplier_identity_verification.openMatiPopup();
            },
            async executeNextOnboardingStep() {
                this.showLoading(true, "Verificando tu identidad...");
                await this.checkIfCanUpdateSupplierOnboardingStep("id-verification");
                await this.$emit("updated", 1);
                this.showLoading(false);
            },
            async nextStep() {
                this.showLoading(true, "Verificando tu identidad...");
                await this.checkIfCanUpdateSupplierOnboardingStep("general");
                await this.$emit("updated", 1);
                this.showLoading(false);
            }
        }
    }
</script>